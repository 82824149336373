<template>
	<div class="footer" :class="className + '-footer'">	
		<ul>
			<li><router-link :to="{ name: 'Rule' }">Reglas</router-link></li>
			<li><router-link :to="{ name: 'About' }">Nosotros</router-link></li>
			<li><router-link :to="{ name: 'Contact' }">Contacto</router-link></li>
			<li><router-link :to="{ name: 'Help' }">Ayuda</router-link></li>
		</ul>
		<p>{{ copyright }}</p>
	</div>
</template>

<script>
export default {
	name: 'TheFooter',
	computed: {
		copyright() {
			var d = new Date()
			var year = d.getFullYear()

			return (
				'Copyright © ' +
				year +
				' Miapuestaweb. Todos los derechos reservados.'
			)
		},
		className() {
			return this.$route.name ? this.$route.name.toLowerCase() : ''
		}
	},
}
</script>

<style lang="scss" scoped>

.footer {
	background-color: $black;
	padding: 43px 0 0 290px;
	margin-bottom: 20px;

	&.about-footer, &.contact-footer {
		padding: 0;
		margin: 28px auto;

		p {
			padding: 0;
		}
	}
}

ul {
	@include flex-container(center);
	width: 100%;
	margin-bottom: 30px;
	padding-left: 90px;
	z-index: 10;

	li {

		&::after {
			@extend %italic;
			content: '|';
			color: $white;
			font-size: 29px;
			margin: 0 5px;
		}		

		&:last-child {

			&::after {
				content: '';
			}				
		}
		
		a {
			font-size: 29px;
		}
	}
}

p {
	color: $orangeTheme;
	margin: 0 auto;
	text-align: center;
	padding-left: 216px;
	font-size: 23px;
}

@include tabletLandscape {

	.footer {
		background-color: transparent;
		padding: 0;
		width: 100%;

		ul {
			padding: 0;
		}

		p {
			padding: 0;
		}
	}
}

@include mobile {

	ul {
		padding: 0;
		margin-bottom: 33px;

		li {

			&::after {
				font-size: 4.8vw;
				margin: 0 4px;
			}
			a {
				font-size: 4.8vw;
			}
		}
	}

	p {
		font-size: 11px;
		padding: 0;
		letter-spacing: .2px;
	}
}
</style>
