const axios = require('axios')

export default {

	state: {
		banks: [],
		response: '',
		currencies: null,
		loading: false
	},

	getters: {
		paymentLoading: state => {
			return state.loading
		},				
		banks: state => {
			return state.banks
		},				
		currencies: state => {
			return state.currencies
		},				
		paymentResponse: state => {
			return state.response
		},				
		withdrawalResponse: state => {
			return state.response
		},				
	},

	actions: {
		getBanks({ state, commit }) {

			return new Promise((resolve, reject) => {
				let url = window.location.hostname == 'localhost' ? 'http://localhost:3000/banks' : '/api/banks'
					state.loading = true
					axios.get(url)
						.then(response => {
							commit('setBanks', response.data)
							resolve(response)
						})
						.catch(error => {
							console.log(error)
							reject(error)
							state.loading =  false
						})						
			})
		},
		setPayment({ state, commit }, payload) {

			return new Promise((resolve, reject) => {
				let url = window.location.hostname == 'localhost' ? 'http://localhost:3000/banks' : '/api/payment'
					state.loading = true
					axios.post(url, payload)
						.then(response => {
							commit('setPayment', response.data)
							resolve(response)
						})
						.catch(error => {
							console.log(error)
							reject(error)
							state.loading =  false
						})						
			})
		},
		setWithdrawal({ state, commit }, payload) {

			return new Promise((resolve, reject) => {
				let url = window.location.hostname == 'localhost' ? 'http://localhost:3000/banks' : '/api/withdrawal'
					state.loading = true
					axios.post(url, payload)
						.then(response => {
							commit('setWithdrawal', response.data)
							resolve(response)
						})
						.catch(error => {
							console.log(error)
							reject(error)
							state.loading =  false
						})						
			})

		},
		getCurrencies({state, commit}) {
			return new Promise((resolve, reject) => {
				state.loading = true
				axios.get('/api/currencies')
					.then(response => {
						commit('setCurrencies', response.data)
						resolve(response.data)
					})
					.catch(error => {
						console.log(error)
						reject()
						state.loading = false
					})
			})
		}
	},

	mutations: {
		setBanks(state, payload) {
			state.banks = payload
			state.loading =  false
		},
		setCurrencies(state, payload) {
			state.currencies = payload
			state.loading = false
		},
		
		setPayment(state, payload) {
			state.response = payload.message
			state.loading =  false
		},
		setWithdrawal(state, payload) {
			state.response = payload.message
			state.loading =  false
		},
		clearResponse(state) {
			state.response = ''
		},		
	},

}