export const betMixin = {
	methods: {
		updateAmount() {
			this.sumBets()
			let amount = !this.betType ? null : this.amount
			this.$store.commit('setTotal', amount)
		},
		sumBets() {
			if (!this.betType) {
				let amount = 0
				this.bets.forEach(bet => {
					amount += parseFloat(bet.monto)
				});
				this.$store.commit('setOnBet', amount)
			} else {					
				this.$store.commit('setOnBet', this.amount)
			}
		},
		detail(bet) {
			let game = this.$store.getters.games.find(game => game.id == bet.idgame)
			let teams = ''
			let selectedTeam = ''
			let printing = typeof bet.equipo === 'object'

			
			for (let index = 0; index < game.teams.length; index++) {
				const team = game.teams[index];
				teams += printing ? '<b>' + team.nombre + '</b>' : team.nombre.substring(0, 3)
				teams += index != game.teams.length - 1 ? ' - ' : ''

				let betTeam = printing ? bet.equipo.id : bet.equipo
				
				if (team.id == betTeam && !this.$store.getters.noTeamBets.includes(bet.tipo_probabilidad)) {
					selectedTeam = team.nombre
				}
			}

			let sign = bet.games_probs_signo ? bet.games_probs_signo.signo : ''

			let cant = bet.cantidad ? bet.cantidad : ''
			
			return teams + ' ' + bet.tipo_apuesta.nombre + ' ' + selectedTeam + ' ' + sign + cant + ' ' + this.achievement(bet.probabilidad)
		},
		achievement(value) {
			if (!this.user) {
				return value				
			} else {
				
				if (this.user.logro == 0) {
					return value
				} else {
					if (value >= 2) {
						let american = (value-1)*100;
						return "+" + american.toPrecision(3);
					}
					else {
						let american = -100/(value-1);
						if (value == 0) {
							return '+' + american.toPrecision(3);
						}
						else {
							return american.toPrecision(3);
						}
					}
				}			
			}
		}
	},
	computed: {
		betType() {
			return this.$store.getters.betType
		},
		bets() {
			return this.$store.getters.bets
		},
		amount() {
			return this.$store.getters.amount
		},		
		user() {
			return this.$store.getters.user
		},		
	}
}