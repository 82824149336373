<template>
	<div>
		<div class="form">
			<div class="field-group">
				<label for="email">Correo Electrónico:</label>
				<input type="email" v-model="credentials.email">
			</div>
			<div class="field-group">
				<label for="email">Contraseña:</label>
				<input @keypress.enter="login" type="password" v-model="credentials.password">
			</div>
			<p class="error" v-if="error">{{ error }}</p>
			<div class="form-bottom">
				<a class="medium" href="/password/email">¿Olvidaste tu contraseña?</a>
				<button class="modal-default-button button small" @click="login">
					Iniciar Sesión
				</button>
			</div>				
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Login',
		data() {
			return {
				credentials: {
					email: '',
					password: '',
				},
				error: ''
			}
		},
		methods: {
			login() {
				this.$store.dispatch('login', this.credentials)
					.then(() => {
						this.$store.dispatch('getBalance')
							.then(() => {
								this.$emit('close')
								this.$router.push({name: 'Playground'})
							})
					})
					.catch(error => {
						this.error = error
					})

			}
		}
	}

</script>

<style lang="scss" scoped>
	.error {
		@extend %bold;
		font-size: 10px;
		color: $orangeTheme;
	}
</style>