<template>
	<div class="backdrop" v-if="loading">
		<div class="loading">
			<PulseLoader color="#ea7c0f"/>
		</div>
	</div>
</template>

<script>
	import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
	export default {
		name: 'Loading',
		components: {
			PulseLoader
		},
		computed: {
			loading() {
				return this.$store.getters.betsLoading || this.$store.getters.scoreLoading || this.$store.getters.contactLoading || this.$store.getters.authLoading || this.$store.getters.paymentLoading 
			}
		}
	}
</script>

<style lang="scss" scoped>
	.backdrop {
		background-color: $blackTransparent65;
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: 10000;
	}

	.loading {
		@include Y-50;
		position: fixed;
    	right: 50%;
		top: 50%;
		z-index: 6;
	}
</style>