<template>
	<div>
		<div class="form">
			
			<div class="field-group" :class="{ 'field-group--error': $v.bank.$invalid }">
				<label for="bank">Banco:</label>
				<VueSelect :code="'id'" :label="'nombre'" v-model="bank" :options="banks"/>
				<div class="error" v-if="!$v.bank.required">{{ $store.getters.getValidationMessage('required') }}</div>
			</div>

			<div class="field-group" :class="{ 'field-group--error': $v.type.$invalid }">
				<label for="type">Tipo de Cuenta:</label>
				<VueSelect v-model="type" :options="typeOptions"/>
				<div class="error" v-if="!$v.type.required">{{ $store.getters.getValidationMessage('required') }}</div>				
			</div>				
			
			<div class="field-group" :class="{ 'field-group--error': $v.account.$invalid }">
				<label for="account">Número de cuenta:</label>
				<input type="text" v-model="account">
				<div class="error" v-if="!$v.account.required">{{ $store.getters.getValidationMessage('required') }}</div>
				<div class="error" v-if="!$v.account.decimal"> {{ $store.getters.getValidationMessage('decimal') }}</div>
				<div class="error" v-if="!$v.account.minLength">{{$v.account.$params.minLength.min}} {{ $store.getters.getValidationMessage('minLength') }}</div>
				<div class="error" v-if="!$v.account.maxLength">{{$v.account.$params.maxLength.max}} {{ $store.getters.getValidationMessage('maxLength') }}</div>
			</div>

			<div class="form-bottom">
				<InvisibleRecaptcha 
					sitekey="6LdZhkEaAAAAAEG9nD0rZFwI4Xu9WPI45Tu-HD6W"
					:callback="send"					
					class="modal-default-button button small"
				>
				Enviar
				</InvisibleRecaptcha>
			</div>				


		</div>
		<template v-if="accounts.length > 0">
			<h2>Cuentas Registradas</h2>
			<div class="accounts">
				<p v-for="account in accounts" :key="account.id">
					<svg @click="confirmRemove(account.id)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z"/></svg>
					<svg @click="edit(account.id)" class="edit"  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14.078 4.232l-12.64 12.639-1.438 7.129 7.127-1.438 12.641-12.64-5.69-5.69zm-10.369 14.893l-.85-.85 11.141-11.125.849.849-11.14 11.126zm2.008 2.008l-.85-.85 11.141-11.125.85.85-11.141 11.125zm18.283-15.444l-2.816 2.818-5.691-5.691 2.816-2.816 5.691 5.689z"/></svg>
					{{ bankName(account.banco_id) }} - {{ account.nrocuenta }}</p>
			</div>
		</template>

		<Modal :show="response" @close="close" :className="{ 'alert': true, 'error': false }">
			<template v-slot:title>
				<h3 v-html="response.title"></h3>			
			</template>
			<template v-slot:form>
				<p v-html="response.message"></p>
				<div class="confirm-buttons" v-if="confirm">
					<button @click="remove(forDelete)" class="button">Si</button>
					<button @click="notRemove" class="button">No</button>
				</div>
			</template>
			
		</Modal>			
	</div>
</template>

<script>
	import { required, decimal, minLength, maxLength } from 'vuelidate/lib/validators'
	import VueSelect from "vue-select"
	import Modal from './Modal.vue'
	import InvisibleRecaptcha from 'vue-invisible-recaptcha';
	export default {
		name: 'Account',
		components: {
			VueSelect,
			Modal,
			InvisibleRecaptcha,
		},
		data(){
			return {
				bank: '',
				account: '',
				type: '',
				typeOptions: ['Ahorro', 'Corriente', 'FAL'],
				response: null,
				editing: null,
				forDelete: null,
				confirm: false,
			}
		},
		validations: {
			bank: {
				required
			},
			account: {
				required,
				decimal,
				minLength: minLength(20),
				maxLength: maxLength(20),
			}, 
			type: {
				required,
			}
		},
		computed: {
			banks() {
				return this.$store.getters.banks
			},
			accounts() {
				return this.$store.getters.user.accounts
			},			
		},
		created() {
			if (this.banks.length == 0) {
			
				this.$store.dispatch('getBanks')
			}
		},		
		methods: {
			bankName(id) {
				if (this.banks.length > 0) {
					return this.banks.find(bank => bank.id == id).nombre					
				}
			},
			send() {
				this.$v.$touch()
				if (this.$v.$invalid) {
					this.response = 'No se pueden enviar datos invalidos'
				} else {					
					let payload = {
						banco: this.bank.id,
						nrocuenta: this.account,
						tipo: this.type,
					}

					if(!this.editing) {
						this.store(payload)
					} else {
						this.update(payload)
					}
				}				
			},
			store(payload) {

				this.$store.dispatch('storeAccount', payload)
					.then(response => {
						this.response = response
					})
			},
			update(payload) {
				payload.id = this.editing.id
				this.$store.dispatch('updateAccount', payload)
					.then(response => {
						this.response = response
						this.editing = null
					})				
			},
			edit(id) {
				this.editing = this.accounts.find(account => account.id === id)				
				this.bank = this.banks.find(bank => bank.id === this.editing.banco_id)
				this.type = this.editing.tipo
				this.account = this.editing.nrocuenta
			},			
			close() {
				this.response = null
				this.$v.$touch()
				if (! this.$v.$invalid) {
					this.clear()
				}					
			},
			confirmRemove(id) {
				this.response = {
					title: 'Confirmar',
					message: '¿Esta seguro de borrar ésta cuenta?',
				}
				this.confirm = true
				this.forDelete = id
			},
			notRemove() {
				this.confirm = false
				this.forDelete = null
				this.response = null		
			},
			remove(id) {				
				this.$store.dispatch('removeAccount', id)
					.then(response => {
						this.response = response
						this.forDelete = null
						this.confirm = false
					})
			},
			clear() {
				this.bank = ''
				this.account = ''
				this.type = ''
			}			
		}
	}
</script>

<style lang="scss" scoped>

	h2 {
		@extend %bold;
		font-size: 19px;
		color: $orangeTheme;
		margin: 10px 0;
	}
	
	.accounts {
		@include border-radius(10px);
		overflow-y: auto;
		width: 95%;		
		background-color: $darkGray2;	
		color: $white;
		padding: 10px;
		max-height: 100px;

		p {
			@include flex-container(flex-start, center, center);
			@include effect (all, .3s, ease-in-out);
			margin: 15px 0;
			color: $orange;
			cursor: pointer;
		}

		svg {
			@include effect (all, .3s, ease-in-out);
			fill: $orange;			
			height: 24px;
			width: 24px;
			margin-right: 5px;

			@include nomobile {

				&:hover {
					fill: $orangeTheme2;

					&.edit {
						fill: $orangeTheme;
					}
				}
			}
		}
	}

	.confirm-buttons {
		@include flex-container(flex-end);
		width: 100%;
		position: absolute;
		bottom: 20px;
		right: 5px;

		.button {
			margin-left: 10px;
		}
	}
</style>