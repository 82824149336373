import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
	{
		path: "/",
		name: "Home",
		component: () =>
			import(/* webpackChunkName: "home" */ "../views/Home.vue")
	},
	{
		path: "/about",
		name: "About",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/About.vue")
	},
	{
		path: "/score",
		name: "Score",
		component: () =>
			import(/* webpackChunkName: "score" */ "../views/Score.vue")
	},
	{
		path: "/contact",
		name: "Contact",
		component: () =>
			import(/* webpackChunkName: "contact" */ "../views/Contact.vue")
	},
	{
		path: "/rule",
		name: "Rule",
		component: () =>
			import(/* webpackChunkName: "rule" */ "../views/Rule.vue")	
	},
	{
		path: "/help",
		name: "Help",
		component: () =>
			import(/* webpackChunkName: "help" */ "../views/Help.vue")	
	},
	{
		path: "/playground",
		name: "Playground",
		meta: { requiresAuth: true },
		component: () =>
			import(/* webpackChunkName: "playground" */ "../views/Playground.vue")	
	},
	{
		path: "/historial",
		name: "History",
		meta: { requiresAuth: true },
		component: () =>
			import(/* webpackChunkName: "history" */ "../views/History.vue")	
	},
	{
		path: "*",
		name: "NotFound",
		component: () =>
			import(/* webpackChunkName: "NotFound" */ "../views/NotFound.vue")	
	}
];

const router = new Router({
	mode: 'history',
	base: '/',
	routes,
	scrollBehavior: (to, from, savedPosition) => {
		if (savedPosition) {
			return savedPosition;
		} else if (to.hash) {
			return {
				selector: to.hash
			};
		} else {
			return { x: 0, y: 0 };
		}
	}	  
});

router.beforeEach((to, from, next) => {
	const loggedIn = localStorage.getItem('user')
	
	if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
		next('/')		
	}

	if (to.name == 'Home' && (loggedIn)) {
		next('/playground')				
	}
	

	next()
})

export default router;
