<template>	
	<div id="nav" :class="{behind: behind}">
		<ul class="menu">
			<li>
				<router-link :to="{ name: 'Home' }">Inicio</router-link>
			</li>
			<li>
				<router-link :to="{ name: 'Score' }">Resultados</router-link>
			</li>
			<li>
				<router-link :to="{ name: 'Contact' }">Contacto</router-link>
			</li>
			<template v-if="local">
				<li>
					<a href="#" @mouseover="showSubmenu = true" @mouseleave="showSubmenu = false">Juan</a>
				</li>				
			</template>
			<template v-else>
				<li v-if="!user">
					<a href="#" @click="$emit('openLogin')">Iniciar Sesión</a>
				</li>				
				<li v-else>
					<a href="#" @mouseover="showSubmenu = true" @mouseleave="showSubmenu = false">{{ user.first_name }}</a>
				</li>
			</template>
		</ul>
		<a  v-if="!user" href="#" @click="$emit('openRegister')" class="button black">Registro</a>
		<a  v-else href="#" @click="logout" class="button black hide-mobile">Salir</a>
		<ul class="submenu" :class="{ collapsed: showSubmenu }" @mouseover="showSubmenu = true" @mouseleave="showSubmenu = false">
			<li><a @click.prevent="open('Payment')" class="submenu-item" href="#">Pagos</a></li>
			<li><a @click.prevent="open('Withdrawals')" class="submenu-item" href="#">Retiros</a></li>
			<li><a @click.prevent="open('Account')" class="submenu-item" href="#">Mis Cuentas</a></li>
			<li><router-link @click="showSubmenu = false" class="submenu-item" :to="{name: 'History'}">Historial</router-link></li>
			<li><a @click.prevent="open('Profile')" class="submenu-item" href="#">Perfil</a></li>			
			<li v-if="user && user.role == 'admin'"><a class="submenu-item" href="/admin" target="_blank" rel="noopener noreferrer">Administrar</a></li>
			<li><a href="#" @click="logout" class="just-mobile">Salir</a></li>
		</ul>		
	</div> 
		
</template>

<script>
export default {
	name: 'TheNav.vue',
	data() {
		return {
			showSubmenu: false,
		}
	},
	computed: {
		user() {
			return this.$store.getters.user
		},
		behind() {
			return this.$store.getters.showDetail
		},
		local() {
			return window.location.hostname == 'localhost'
		}
	},
	methods: {
		logout() {
			this.$store.dispatch('logout')
				.then(() => {
					this.$router.push({name: 'Home'})
					this.showSubmenu = false
				})
		},
		open(component) {
			this.showSubmenu = false
			this.$emit('open' + component)
		}
	}
}
</script>

<style lang="scss" scoped>
.header {
	z-index: 5;
}

#nav {
	@include flex-container;
    position: absolute;
    z-index: 5;
    margin-top: 57px;
	margin-right: 94px;
    right: 0;
}

.menu {
	@include flex-container(flex-start, flex-start, flex-start, nowrap);
	@include clip-path(polygon(0 0, 100% 0%, 100% 100%, 3.3% 100%));
    background: $black;
	padding-right: 40px;

    li {
		margin: 13px 0 13px 3.125vw;
	}	
}

.submenu {
	@include effect(all, .3s, ease-in-out);
	@include flex-container(flex-start, flex-start, flex-start, nowrap, column);
	@include border-radius(0 0 5px 5px);	
	max-height: 0;
	background-color: $black;
	position: absolute;
	top: 50px;
	right: 88px;
	z-index: -1;
	overflow: hidden;
	padding: 0 10px 0;

	li {
		margin-bottom: 10px;
	}

	&.collapsed {
		max-height: 1000px;
		padding: 10px;
	}
}

.button {
	margin-top: -7px;
	margin-left: -18px;
}

@include tabletLandscape {
	
	#nav {		
		background-color: $orangeTheme;
		margin-top: 26px;
		padding: 4px 0 7px;
		width: 100%;
		position: relative;

	}

	.menu {
		@include flex-container(center, center, center);
		@include clip-path(polygon(0 0, 100% 0%, 100% 100%, 0% 100%));
		
		width: 100%;
		padding: 0;

		li {
			margin: 6px 2.667vw 7px;

			&:first-child {
				margin-left: 5px;
			}
		}
	}
	
	.submenu {
		border: 2px solid $orangeTheme;
		right: 166px;
		top: 43px;
	}
	
	.button {
		position: absolute;
		top: 197%;
		left: 0; 
  		right: 0; 
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		width: 22%;
	}	
}

@include justTabletPortrait {

	.submenu {
		right: 64px;
	}
}

@include mobile {

	#nav {

		&.behind {
			z-index: 3;
		}
	}

	.submenu {		
		top: 34px;
		right: 1px;
		//padding: 0 2px 0;

		li {
			margin-bottom: 5px;
		}		
	}

	.button {
		top: 197%;
		width: 100px;
	}
}
</style>
