<template>
	<div>
	<div class="form">
			<div class="field-group" :class="{ 'field-group--error': $v.name.$invalid }">
				<label for="name">Nombre:</label>
				<input type="text" v-model="name">
				<div class="error" v-if="!$v.name.required">{{ $store.getters.getValidationMessage('required') }}</div>
				<div class="error" v-if="!$v.name.minLength">{{$v.name.$params.minLength.min}} {{ $store.getters.getValidationMessage('minLength') }}</div>				
			</div>			
			<div class="field-group" :class="{ 'field-group--error': $v.email.$invalid }">
				<label for="email">Correo Electrónico:</label>
				<input type="email" v-model="email">			
				<div class="error" v-if="!$v.email.required">{{ $store.getters.getValidationMessage('required') }}</div>
				<div class="error" v-if="!$v.email.email">{{ $store.getters.getValidationMessage('email') }}</div>
				
			</div>
			<div class="field-group" :class="{ 'field-group--error': $v.password.$invalid }">
				<label for="password">Contraseña:</label>
				<input type="password" v-model="password">
				<div class="error" v-if="!$v.password.required">{{ $store.getters.getValidationMessage('required') }}</div>
				<div class="error" v-if="!$v.password.minLength">{{$v.password.$params.minLength.min}} {{ $store.getters.getValidationMessage('minLength') }}</div>				
			</div>
			<div class="field-group" :class="{ 'field-group--error': $v.confirm.$invalid }">
				<label for="confirm">Confirmar Contraseña:</label>
				<input type="password" v-model="confirm">
				<div class="error" v-if="!$v.confirm.required">{{ $store.getters.getValidationMessage('required') }}</div>
				<div class="error" v-if="!$v.confirm.sameAsPassword">{{ $store.getters.getValidationMessage('sameAsPassword') }}</div>				
			</div>
			<div class="field-group" :class="{ 'field-group--error': $v.identification.$invalid }">
				<label for="identification">Cédula:</label>
				<input type="text" v-model="identification">
				<div class="error" v-if="!$v.identification.required">{{ $store.getters.getValidationMessage('required') }}</div>
				<div class="error" v-if="!$v.identification.minLength">{{$v.identification.$params.minLength.min}} {{ $store.getters.getValidationMessage('minLength') }}</div>				
			</div>
			<div class="field-group" :class="{ 'field-group--error': $v.birthdate.$invalid }">
				<label for="birthdate">Fecha de Nacimiento:</label>

				<date-picker :masks="masks" @dayclick="dateSelected" v-model="birthdate" :max-date="new Date()" class="register-calendar"> 
					<template v-slot="{ inputValue, togglePopover }">
						<div class="flex items-center">
							<button
							class="p-2 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
							@click="togglePopover()"
							>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
								class="w-4 h-4 fill-current"
							>
								<path
								d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
								></path>
							</svg>
							</button>
							<input
							:value="inputValue"
							class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
							readonly
							/>
						</div>
						</template>
				</date-picker>

				<div class="error" v-if="!$v.birthdate.required">{{ $store.getters.getValidationMessage('required') }}</div>
			</div>
			<div class="field-group" :class="{ 'field-group--error': $v.phone.$invalid }">
				<label for="phone">Telefono:</label>
				<input type="text" v-model="phone">
				<div class="error" v-if="!$v.phone.required">{{ $store.getters.getValidationMessage('required') }}</div>
				<div class="error" v-if="!$v.phone.minLength">{{$v.phone.$params.minLength.min}} {{ $store.getters.getValidationMessage('minLength') }}</div>				
			</div>

			<div class="form-bottom">
				<InvisibleRecaptcha 
					sitekey="6LdZhkEaAAAAAEG9nD0rZFwI4Xu9WPI45Tu-HD6W"
					:callback="register"					
					class="modal-default-button button small"
				>
				Registrar
				</InvisibleRecaptcha>
				<!--
					<button @click="register" class="modal-default-button button small">Registro SR</button>
				 -->
			</div>				
		</div>
		<Modal :show="response != ''" @close="response = ''" :className="{ 'alert': true, 'error': $v.$invalid }">
			<template v-slot:title>
				<h3>Registro</h3>			
			</template>
			<template v-slot:form>
				<p>{{ response }}</p>
			</template>
		</Modal>		
	</div>
</template>

<script>
	import { required, minLength, email, sameAs} from 'vuelidate/lib/validators'
	import Modal from './Modal.vue'
	import InvisibleRecaptcha from 'vue-invisible-recaptcha';
	import DatePicker from 'v-calendar/lib/components/date-picker.umd'

	export default {
		name: 'Register',
		components: {
			Modal,
			InvisibleRecaptcha,
			DatePicker,
		},
		data() {
			return {				
				name: '',
				email: '',
				password: '',
				confirm: '',
				identification: '',
				birthdate: '',				
				phone: '',
				response: '',
				masks: {
					input: 'DD-MM-YYYY',
				},						
			}
		},
		validations: {
			name: {
				required,
				minLength: minLength(4),
			},
			email: {
				required,
				email,
				minLength: minLength(4)
			},
			password: {
				required,
				minLength: minLength(8),
			},			
			confirm: {
				required,
				sameAsPassword: sameAs('password'),
			},			
			identification: {
				required,
				minLength: minLength(6),
			},			
			birthdate: {
				required,				
			},			
			phone: {
				required,
				minLength: minLength(7),
			},			
		},		
		methods: {
			register() {
				this.$v.$touch()
				if (this.$v.$invalid) {
					this.response = 'No se pueden enviar datos invalidos'
				} else {				
					let user = {
						name: this.name,
						email: this.email,
						password: this.password,
						password_confirmation: this.confirm,
						identification: this.identification,
						birthdate: this.birthdate,
						phone: this.phone,					
					}

					this.$store.dispatch('register', user)
						.then(() => {
							this.response = 'Usuario registrado exitosamente'
							this.$emit('close')
							this.$router.push({name: 'Playground'})
						})
						.catch(response => {
							let error = response.data.errors.email
							if (error) {								
								this.response = error[0]
							} else {
								this.response('Datos Inválidos')
							}
						})
				}

			},
			dateSelected(payload) {
				this.birthdate = payload.id
			},			
		}
	}

</script>

<style lang="scss" scoped>
	.button {
		position: absolute;
		left: -105px;
		bottom: -113px;

		&:disabled {
			background-color: $orange;
			color: $darkGray2;
		}
	}

	@include mobile {
		
		.button {
			position: relative;		
			left: 0;
			bottom: 0;
		}
	}
</style>