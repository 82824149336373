<template>
	<div>
		<div class="form">

			<div class="field-group" :class="{ 'field-group--error': $v.fecha_hora.$invalid }">
				
				<div class="bank-info">
					<div>
						<b>Banco de Venezuela Cuenta Corriente</b> 01020228130000067234 						
					</div>
					<div>
						<b>Fernando Rivas</b> CI: 17266490 
					</div>
					<div>
						<b>Pago Móvil</b> CI: 17266490 Tlf: 04122985469 Banco de Venezuela (0102)
					</div>
				</div>
				<label for="fecha_hora">Fecha de Pago:</label>

				<date-picker locale="es" :masks="masks" @dayclick="dateSelected" v-model="fecha_hora" :max-date="new Date()"> 
					<template v-slot="{ inputValue, inputEvents }">
						<input
							:value="inputValue"
							v-on="inputEvents"
						/>
					</template>
				</date-picker>
				
				<div class="error" v-if="!$v.fecha_hora.required">{{ $store.getters.getValidationMessage('required') }}</div>
			</div>

			<div class="field-group" :class="{ 'field-group--error': $v.monto.$invalid }">
				<label for="monto">Monto:</label>
				<input type="text" v-model="monto">
				<div class="error" v-if="!$v.monto.required">{{ $store.getters.getValidationMessage('required') }}</div>
				<div class="error" v-if="!$v.monto.decimal"> {{ $store.getters.getValidationMessage('decimal') }}</div>
				<!-- <div class="error" v-if="!$v.monto.minValue"> {{ $store.getters.getValidationMessage('minValue') }}</div> -->
			</div>	

			<div class="field-group" :class="{ 'field-group--error': $v.tipo.$invalid }">
				<label for="tipo">Tipo de pago:</label>
				<VueSelect :code="'id'" :label="'nombre'" v-model="tipo" :options="types"/>
				<div class="error" v-if="!$v.tipo.required">{{ $store.getters.getValidationMessage('required') }}</div>				
			</div>
			
			<div class="field-group" :class="{ 'field-group--error': $v.referencia.$invalid }">
				<label for="referencia">Referencia:</label>
				<input type="text" v-model="referencia">
				<div class="error" v-if="!$v.referencia.required">{{ $store.getters.getValidationMessage('required') }}</div>				
			</div>
			
			<div class="field-group" :class="{ 'field-group--error': $v.banco.$invalid }">
				<label for="banco">Banco:</label>
				<VueSelect :code="'id'" :label="'nombre'" v-model="banco" :options="banks"/>
				<div class="error" v-if="!$v.banco.required">{{ $store.getters.getValidationMessage('required') }}</div>
			</div>
			
			<div class="field-group" :class="{ 'field-group--error': $v.titular.$invalid }">
				<label for="titular">Titular:</label>
				<input type="text" v-model="titular">
				<div class="error" v-if="!$v.titular.required">{{ $store.getters.getValidationMessage('required') }}</div>
				<div class="error" v-if="!$v.titular.minLength">{{$v.titular.$params.minLength.min}} {{ $store.getters.getValidationMessage('minLength') }}</div>
			</div>

			<div class="form-bottom">
				<InvisibleRecaptcha 
					sitekey="6LdZhkEaAAAAAEG9nD0rZFwI4Xu9WPI45Tu-HD6W"
					:callback="pay"					
					class="modal-default-button button small"
				>
				Enviar
				</InvisibleRecaptcha>
			</div>			
		</div>
		<Modal :show="response != ''" @close="close" :className="{ 'alert': true, 'error': false }">
			<template v-slot:title>
				<h3>Pago</h3>			
			</template>
			<template v-slot:form>
				<p>{{ response }}</p>
			</template>
		</Modal>	
	</div>
</template>

<script>
	import { required, minLength, decimal } from 'vuelidate/lib/validators'
	import InvisibleRecaptcha from 'vue-invisible-recaptcha';
	import DatePicker from 'v-calendar/lib/components/date-picker.umd'
	import VueSelect from "vue-select";
	import "vue-select/dist/vue-select.css";
	import Modal from './Modal'
	export default {
		name: 'Paymemts',
		components: {
			Modal,
			InvisibleRecaptcha,
			DatePicker,
			VueSelect,
		},		
		data() {
			return {
				fecha_hora: '',
				monto: '',
				tipo: null,
				referencia: '',
				banco: '',
				titular: '',
				types: [
					{id: 1, nombre: 'Depósito'},
					{id: 2, nombre: 'Transferencia'},
				],
				masks: {
					input: 'DD-MM-YYYY',
				},
				response: '',
			}
		},
		validations: {
			fecha_hora: {
				required,
			},
			monto: {
				required,
				decimal,
				//minValue: min(1),
			},
			tipo: {
				required
			},
			referencia: {
				required,
				minLength: minLength(4),
			},
			banco: {
				required
			},
			titular: {
				required,
				minLength: minLength(4),
			},		
		},
		computed: {
			banks() {
				return this.$store.getters.banks
			},
			paymentResponse() {
				return this.$store.getters.paymentResponse
			},
		},
		created() {
			if (this.banks.length == 0) {
			
				this.$store.dispatch('getBanks')
			}
		},
		methods: {
			dateSelected(payload) {
				this.fecha_hora = payload.id
			},
			pay() {
				this.$v.$touch()
				if (this.$v.$invalid) {
					this.response = 'No se pueden enviar datos invalidos'
				} else {
					let payload = {
						fecha_hora: this.fecha_hora,
						monto: this.monto,
						tipo: this.tipo.id,
						referencia: this.referencia,
						banco: this.banco.id,
						titular: this.titular,					
					}
	
					this.$store.dispatch('setPayment', payload)
				}				
			},
			close() {
				this.response = ''
				this.$store.commit('clearResponse')
				this.$v.$touch()
				if (! this.$v.$invalid) {
					this.$emit('close')
				}			
			}
		},
		watch: {
			paymentResponse(val) {
				if (val) {
					this.response = val
				}
			}
		}
	}
</script>

<style lang="scss" scoped>

</style>