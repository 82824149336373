<template>
	<div>
		<div class="form">
			<div class="field-group" :class="{ 'field-group--error': $v.account.$invalid }">
				<label for="account">Cuenta Bancaria:</label>
				<VueSelect v-model="account" :options="options"/>
				<div class="error" v-if="!$v.account.required">{{ $store.getters.getValidationMessage('required') }}</div>				
			</div>		

			<div class="field-group" :class="{ 'field-group--error': $v.monto.$invalid }">
				<label for="monto">Monto:</label>
				<input type="text" v-model="monto">
				<div class="error" v-if="!$v.monto.required">{{ $store.getters.getValidationMessage('required') }}</div>
				<div class="error" v-if="!$v.monto.decimal"> {{ $store.getters.getValidationMessage('decimal') }}</div>
		
			</div>	
			
			<div class="form-bottom">
				<button v-if="local" @click="withdrawal" class="modal-default-button button small">Enviar</button>
				<InvisibleRecaptcha 
					v-else
					sitekey="6LdZhkEaAAAAAEG9nD0rZFwI4Xu9WPI45Tu-HD6W"
					:callback="withdrawal"					
					class="modal-default-button button small"
				>
				Enviar
				</InvisibleRecaptcha>			
			</div>
		</div>
		<Modal :show="response != ''" @close="close" :className="{ 'alert': true, 'error': false }">
			<template v-slot:title>
				<h3>Retiros</h3>			
			</template>
			<template v-slot:form>
				<p>{{ response }}</p>
			</template>
		</Modal>			
	</div>
</template>

<script>
	import VueSelect from "vue-select";
	import InvisibleRecaptcha from 'vue-invisible-recaptcha';
	import { required, decimal } from 'vuelidate/lib/validators'
	import Modal from "./Modal.vue"
	export default {
		name: 'Withdrawals',
		components: {
			VueSelect,
			InvisibleRecaptcha,
			Modal,
		},
		data() {
			return {
				account: '',
				monto: null,
				response: '',
			}
		},
		computed: {
			accounts() {
				return this.$store.getters.user.accounts
			},
			banks() {
				return this.$store.getters.banks
			},
			options() {
				let options = []
				if(this.banks.length > 0) {

					this.accounts.forEach(account => {
						options.push({
							code: account.id,
							label: this.bankName(account.banco_id) + ' - ' + account.nrocuenta
						})
					});
				}

				return options
			},
			local() {
				return window.location.hostname == 'localhost'
			},
			withdrawalResponse() {
				return this.$store.getters.withdrawalResponse
			}
		},
		validations: {
			account: {
				required
			}, 
			monto: {
				required,
				decimal
			}
		},
		created() {
			if (this.banks.length == 0) {
			
				this.$store.dispatch('getBanks')
			}
		},
		methods: {
			bankName(id) {
				return this.banks.find(bank => bank.id == id).nombre
			},
			withdrawal(){
				if (this.$v.$invalid) {
					this.response = 'No se pueden enviar datos invalidos'
				} else {					
					let payload = {
						monto: this.monto,
						cuenta_id: this.account.code
					}

					this.$store.dispatch('setWithdrawal', payload)
						.then(() => {
							this.$store.dispatch('getBalance')
						})
				}				
			},
			close() {
				this.response = ''
				this.$store.commit('clearResponse')
				this.$v.$touch()
				if (! this.$v.$invalid) {
					this.$emit('close')
				}				
			}
		},
		watch: {
			withdrawalResponse(val) {
				if (val) {
					this.response = val
				}
			}
		}		
	}
</script>

<style lang="scss" scoped>

</style>