<template>
	<div class="app" :class="$route.name">	
		<Loading/>

		<transition appear name="bounce" enter-active-class="bounceInLeft">	
			<img class="desktop-bars hide-mobile" style="animation-duration: 1.5s" src="./assets/img/desktop-bars.png" alt="">
		</transition>

		<transition appear name="bounce" enter-active-class="bounceInLeft">	
			<img class="mobile-bars just-mobile" style="animation-duration: 1.5s" src="./assets/img/mobile-bars.png" alt="">
		</transition>

		<transition appear name="bounce" enter-active-class="bounceInDown">	
			<TheNav 
				@openLogin="setShowModal({title: 'Inicio<br>de Sesión', component: 'Login', className: ''})"
				@openRegister="setShowModal({title: 'Registro', component: 'Register', className: 'register'})"
				@openPayment="setShowModal({title: 'Pago', component: 'Payment', className: 'payment'})"
				@openWithdrawals="setShowModal({title: 'Retiros', component: 'Withdrawals', className: 'withdrawal'})"
				@openAccount="setShowModal({title: 'Cuentas<br> Bancarias', component: 'Account', className: 'account'})"
				@openProfile="setShowModal({title: 'Perfil', component: 'Profile', className: 'profile'})"
			/>
		</transition>
		
		<TheCarousel :slides="slides"/>
		
		<router-view />

		<Modal :show="modal.show" @close="toggleModal" :class="modal.className">
			<template v-slot:title>
				<h3 v-html="modal.title"></h3>			
			</template>
			<template v-slot:form>
				<component v-bind:is="modal.component" @close="toggleModal"/>				
			</template>
		</Modal>

		<transition appear name="bounce" enter-active-class="bounceInLeft">	
			<TheFooter />
		</transition>
		<Ticket/>
	</div>
</template>

<script>
import Loading from './components/Loading.vue'
import TheNav from './components/TheNav.vue'
import TheCarousel from './components/TheCarousel.vue'
import Modal from './components/Modal.vue'
import Login from './components/Login.vue'
import Register from './components/Register.vue'
import Payment from './components/Payment.vue'
import Withdrawals from './components/Withdrawals.vue'
import Account from './components/Account.vue'
import Profile from './components/Profile.vue'
import TheFooter from './components/TheFooter.vue'
import Ticket from './components/Ticket.vue'
export default {
    name: 'App',
    components: {
		TheNav,
		TheCarousel,
		Modal,
		TheFooter,
		Loading,
		Login,
		Register,
		Payment,
		Withdrawals,
		Account,
		Profile,
		Ticket,
	},
	data() {
		return {
			modal: {
				title: '',
				component: '',
				show: false,
				className: ''
			},
			reset: null,
		}
	},
	created() {
		if (this.user) {			
			this.$store.dispatch('getBalance')
		}

    let uri = window.location.search.substring(1); 
    let params = new URLSearchParams(uri);
	let reset = params.get("reset");

		if (reset == 1) {
			this.setShowModal({title: 'Inicio<br>de Sesión', component: 'Login', className: ''}	)
		}
	},
	computed: {
		slides() {
			return this.$store.getters.getSlides(this.$route.name)
		},
		screenHeight() {
			return window.innerHeight
		},
		user() {
			return this.$store.getters.user
		},
	},
	methods: {
		setShowModal(component) {
			this.modal = component
			this.modal.show = true
			this.$forceUpdate()
		},
		toggleModal() {
			this.modal.show = !this.modal.show
			this.$forceUpdate()
		}
	}
}
</script>

<style lang="scss" scoped>
.app {
	overflow: hidden;
}

.desktop-bars {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 4;
}

ul {
    @include flex-container;
}

@include tabletLandscape {

	.app {
		background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./assets/img/tablet-bg.jpg');
		background-color: $black;
		background-repeat: no-repeat;
		background-size: cover;

		 &.About {
			background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./assets/img/about-tablet-bg.png');
			background-color: $black;
			background-repeat: no-repeat;
			background-size: contain;			
		 }

		 &.Score {
			background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./assets/img/score-tablet-bg.png');
			background-color: $black;
			background-repeat: no-repeat;
			background-size: contain;			
		 }

		&.Rule {
			background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./assets/img/rule-tablet-bg.png');
			background-color: $black;
			background-repeat: no-repeat;
			background-size: contain;			
		 }
	}	

	.desktop-bars {
		left: -65%;
	}	
}

@include mobile {

	.app {
		background-image: url('./assets/img/mobile-bg.png');

		&.About {
			background-image: url('./assets/img/about-mobile-bg.png');			
		}

		&.Score {
			background-image: url('./assets/img/score-mobile-bg.png');	
			background-size: 100%;
		}

		&.Rule {
			background-image: url('./assets/img/rule-mobile-bg.png');	
			background-size: 100%;
		}
		
	}

	.polygon {
		display: none;
	}

	.mobile-bars {
		position: absolute;
		top: 0;
		left: 0;
	}
}
</style>
