<template>
<div class="carousel-content">
	<carousel 
		:autoplay="true"
		:per-page="1" 
		:loop="true" 
		:autoplay-timeout="3500"
		:navigation-enabled="slidesCount > 1"
		:navigation-next-label="''"
		:navigation-prev-label="''"
		:pagination-enabled="false"
		:navigate-to="start"
		class="hide-mobile"
	>
		<slide v-for="(slide, index) in slides" :key="index">
			<img :src="require(`@/assets/${slide.image}`)" :alt="slide.title">	
		</slide> 
		
	</carousel>
</div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
export default {
	name: 'TheCarousel',
	components: {
		Carousel,
		Slide,
	},
	data(){
		return {
			start: [0, true]
		}
	},
	props: {
		slides: Array
	},
	mounted() {		

		setTimeout(() => {
			this.$forceUpdate()
		}, 1000);
	},
	computed: {
		routeName() {
			return this.$route.name
		},
		slidesCount() {
			return this.slides ? this.slides.length : 0
		}
	},
	watch:{
		routeName() {
			this.start = [0, false]
		}
	}
}
</script>

<style lang="scss">

.VueCarousel-navigation-next {
	background-image: url('../assets/img/btn-next.png');
}

.VueCarousel-navigation-prev {
	background-image: url('../assets/img/btn-prev.png');
}

</style>
