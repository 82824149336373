<template>
	<transition name="modal">
		<div v-if="show" class="modal-mask" :class="className" @click="$emit('close')">
			<div class="modal-wrapper">
				<div class="modal-container" @click.stop="">

					<div class="modal-header">
						<slot name="header">
							<img src="../assets/img/gray-bars.png" alt="">
							<svg @click="$emit('close')" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>
						</slot>
					</div>

					<div class="modal-body">
						<div class="modal-icon">
							<slot name="title">
								default title
							</slot>
						</div>
						<slot name="form">
							dafault form
						</slot>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	export default {
		name: 'Modal',
		props: {
			show: {
				default: false,
			},
			className: {
				default: 'login'
			}
		},
		mounted() {
			document.addEventListener("keydown", (e) => {
				if (e.keyCode == 27) {
					this.$emit('close');
				}
			});
		},
	}
</script>

<style lang="scss" scoped>


	.login {
		
		.modal-icon {
			background-image: url('../assets/img/login-title.png');
		}

		.modal-body {
			background-image: url('../assets/img/login-border.png');
		}
	}

	.register, .alert, .confirm, .payment {

		.modal-icon {
			background-image: url('../assets/img/register-title.png');
		}

		.modal-body {
			background-image: url('../assets/img/register-border.png');
		}		
	}

</style>