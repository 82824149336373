<template>
	<div>
		<div class="form">
			<div class="field-group" :class="{ 'field-group--error': $v.name.$invalid }">
				<label for="name">Nombre:</label>
				<input type="text" v-model="name">
				<div class="error" v-if="!$v.name.required">{{ $store.getters.getValidationMessage('required') }}</div>
				<div class="error" v-if="!$v.name.minLength">{{$v.name.$params.minLength.min}} {{ $store.getters.getValidationMessage('minLength') }}</div>				
			</div>
			<div class="field-group" :class="{ 'field-group--error': $v.email.$invalid }">
				<label for="email">Correo Electrónico:</label>
				<input type="email" v-model="email" disabled>				
			</div>

			<div class="field-group" :class="{ 'field-group--error': $v.phone.$invalid }">
				<label for="phone">Telefono:</label>
				<input type="text" v-model="phone">
				<div class="error" v-if="!$v.phone.minLength">{{$v.phone.$params.minLength.min}} {{ $store.getters.getValidationMessage('minLength') }}</div>				
				<div class="error" v-if="!$v.phone.decimal"> {{ $store.getters.getValidationMessage('decimal') }}</div>
			</div>
			<div v-if="user.corredor == 1" class="field-group">
				<label for="printer">Impresora:</label>
				<VueSelect v-model="printer" :options="printerOptions"/>				
			</div>
			<div class="field-group" :class="{ 'field-group--error': $v.logro.$invalid }">
				<label for="logro">Tipo de Logro:</label>
				<VueSelect v-model="logro" :options="logroOptions"/>
				<div class="error" v-if="!$v.logro.required">{{ $store.getters.getValidationMessage('required') }}</div>				
			</div>					
			<div class="form-bottom">
				<InvisibleRecaptcha 
					sitekey="6LdZhkEaAAAAAEG9nD0rZFwI4Xu9WPI45Tu-HD6W"
					:callback="update"					
					class="modal-default-button button small"
				>
				Enviar
				</InvisibleRecaptcha>
			</div>				
		</div>
		<Modal :show="response" @close="close" :className="{ 'alert': true, 'error': false }">
			<template v-slot:title>
				<h3 v-html="response.title"></h3>			
			</template>
			<template v-slot:form>
				<p v-html="response.message"></p>
			</template>			
		</Modal>		
	</div>
</template>

<script>
	import { required, decimal, minLength } from 'vuelidate/lib/validators'
	import VueSelect from "vue-select"
	import Modal from './Modal.vue'
	import InvisibleRecaptcha from 'vue-invisible-recaptcha';
	export default {
		name: 'Profile',
		components: {
			Modal,
			VueSelect,
			InvisibleRecaptcha,
		},
		data(){
			return {
				name: '',
				email: '',
				phone: '',
				printer: '',
				logro: '',	
				response: null,
				printerOptions: [
					{code: 1, label: 'POS 58 Termica'}, 
					{code: 2, label: 'EPSON TM-U325 (MATRIZ DE PUNTO)'},
				],
				logroOptions: [
					{code: 0, label: 'Decimal'}, 
					{code: 1, label: 'Americano'},
				],				
			}
		},
		validations: {
			name: {
				required,
				minLength: minLength(4),
			},
			email: {
				required,
			},
			phone: {
				decimal,
				minLength: minLength(10),
			},
			logro: {
				required,
			},			
		},
		computed: {
			user() {
				return this.$store.getters.user
			},
		},
		created(){
			this.name = this.user.name
			this.email = this.user.email
			this.phone = this.user.phone
			this.printer = this.printerOptions.find(printer => printer.code == this.user.printer)
			this.logro = this.logroOptions.find(logro => logro.code === this.user.logro)
		},
		methods: {
			update() {
				this.$v.$touch()
				if (this.$v.$invalid) {
					this.response = 'No se pueden enviar datos invalidos'
				} else {
					let payload = {
						name: this.name,
						email: this.email,
						phone: this.phone,
						printer: this.printer ? this.printer.code : null,		
						logro: this.logro.code,	
					}
					this.$store.dispatch('updateUser', payload)
						.then(response => {
							this.response = response
							this.$store.dispatch('getBalance')
						})
				}

			},
			close() {
				this.response = null
			}
		}
	}
</script>

<style lang="scss" scoped>

</style>