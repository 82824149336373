import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'

import titleMixin from './mixins/titleMixin'
Vue.mixin(titleMixin)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VueFilterDateParse from '@vuejs-community/vue-filter-date-parse';
Vue.use(VueFilterDateParse);

import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format'
Vue.use(VueFilterDateFormat)

var numeral = require("numeral");

Vue.filter("formatBalance", function (value) {
	return numeral(value).format("0,0.00"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("formatPobability", function (value) {
	if (store.getters.user && store.getters.user.logro == 1 ) {
		return value
	}
	return numeral(value).format("0.00"); // displaying other groupings/separators is possible, look at the docs
});


require('./vendor.js')

new Vue({
	router,
	store,
	created() {
		const userString = localStorage.getItem('user')
		if (userString) {
			const userData = JSON.parse(userString)
			this.$store.commit('setUser', userData)
		}
		axios.interceptors.response.use(
			response => response,
			error => {
				if (error.response.status === 401) {
					this.$store.dispatch('logout')
				}
				return Promise.reject(error)
			}
		)
	},	
	render: h => h(App)
}).$mount('#app')