import Vue from 'vue'
import Vuex from 'vuex'
import Score from './modules/Score'
import Bet from './modules/Bet'
import Auth from './modules/Auth'
import Cashier from './modules/Cashier'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		slides: {
			Home: [
				{
					title: 'Slide Basket',
					image: 'img/slide-basket.jpg',
				},
				{
					title: 'Slide Beisbol',
					image: 'img/slide-beisbol.jpg',
				},
				{
					title: 'Slide Futbol',
					image: 'img/slide-futbol.jpg',
				},
				{
					title: 'Slide Horses',
					image: 'img/slide-horses.jpg',
				},
			],
			Playground: [
				{
					title: 'Slide Basket',
					image: 'img/slide-basket.jpg',
				},
			],			
			About: [
				{
					title: 'Slide Buildings',
					image: 'img/slider-about.jpg',
				},
			],
			Score: [
				{
					title: 'Slide Score 1',
					image: 'img/slide-score-1.jpg',
				},
				{
					title: 'Slide Score 2',
					image: 'img/slide-score-2.jpg',
				},
				{
					title: 'Slide Score 3',
					image: 'img/slide-score-3.jpg',
				},
				{
					title: 'Slide Score 4',
					image: 'img/slide-score-4.jpg',
				},
			],
			Contact: [
				{
					title: 'Slide Contact',
					image: 'img/slide-contact.jpg',
				},
			],			
			Rule: [
				{
					title: 'Slide Rule',
					image: 'img/slide-rule.png',
				},
			],			
			Help: [
				{
					title: 'Slide Help',
					image: 'img/slide-help.jpg',
				},
			],			
		},
		contactLoading: false,
		validationMessages: [
			{
				key: 'required',
				message: 'Campo Obligatorio',
			},
			{
				key: 'minLength',
				message: 'es el mínimo de caracteres requeridos',
			},
			{
				key: 'email',
				message: 'El formato de email es invalido',
			},
			{
				key: 'sameAsPassword',
				message: 'La confirmación y la contraseña debe coincidir',
			},
			{
				key: 'isUnique',
				message: 'El correo existe en nuestra Base de Datos',
			},
			{
				key: 'decimal',
				message: 'Sólo se permiten numeros',
			},
			{
				key: 'minValue',
				message: 'Monto debe ser mayor a cero',
			},
			{
				key: 'maxLength',
				message: 'es el máximo de caracteres requeridos',
			},
		],
	},
	getters: {
		getSlides: (state) => (route) => {
			return state.slides[route]
		},
		getBackground: (state) => (route, size) => {
			if (size < 1025 && size > 420) {
				return state.mobileBg[route].tablet				
			} else if (size <= 421) {
				return "background-image: url('./assets/img/" + state.mobileBg[route].mobile + "')"
			}
			
			return ''
		},
		contactLoading: state => {
			return state.contactLoading
		},
		getValidationMessage: (state) => (key) => {
			return state.validationMessages.find(message => message.key === key).message
		},
	},
	mutations: {
		setContactLoading(state, payload) {
			Vue.set(state, 'contactLoading', payload)
		}
	},
	actions: {
		setContactLoading({ commit }, payload) {
			commit('setContactLoading', payload)
		}
	},
	modules: {
		Score,
		Bet,
		Auth,
		Cashier,
	}
});
