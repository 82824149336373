import Vue from 'vue';
const axios = require('axios');
export default {
	state: {
		data: null,
		loading: false,
	},
	getters: {
		scoreLoading: state => {
			return state.loading
		},		
	},
	actions: {
		getScores({ state, commit }, payload) {

			return new Promise((resolve, reject) => {
				let url = window.location.hostname == 'localhost' ? 'http://localhost:3000/scores' : '/resultado?fecha_cierre=' + payload
				Vue.set(state, 'loading', true)
				axios.get(url)
					.then(response => {
						commit('getScores', response.data)
						resolve(response)
						Vue.set(state, 'loading', false)
					})
					.catch(error => {
						console.log(error)
						reject(error)
						Vue.set(state, 'loading', false)
					})
			})
		},
	},
	mutations: {
		getScores(state, payload) {
			Vue.set(state, 'data', payload)
			Vue.set(state, 'loading', false)
		}
	},
}