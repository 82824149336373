<template>
	<div v-if="bet" class="ticket just-print">
		<h1>MiApuestaWeb.com</h1>
		<p><b>Ticket:</b> {{ bet.id }}</p>
		<p><b>Fecha y Hora:</b> {{ bet.fecha_registro| dateParse('YYYY-MM-DD HH:mm:ss') | dateFormat('DD.MM.YYYY - hh:mm a') }}</p>
		<p><b>Monto:</b> {{bet.monto | formatBalance}}</p>
		<p><b>Ganancia:</b> {{bet.ganancia | formatBalance}}</p>
		<span v-for="(item, index) in details" :key="index">
			{{ index > 0 ? ' / ' : '' }}<b>#{{ index + 1 }}</b> <span v-html="detail(item.games_probs)"></span>
		</span>
	</div>
</template>

<script>
	import {betMixin} from '../mixins/betMixin'
	
	export default {
		name: 'Ticket',
		mixins: [betMixin],
		computed: {
			bet(){
				return this.$store.getters.bet
			},
			details() {
				let items = this.bet.bets_detail
				let arr = []
				items.forEach(item => {
					console.log(item)
					arr.push(item)
				});

				return arr
			}
		}
	}
</script>

<style lang="scss" scoped>

	.ticket {
		
		h1 {
			@extend %bold;
			font-size: 10px;
			text-align: center;
			margin-bottom: 20px
		}
		
		p, span {
			@extend %italic;
			font-size: 8px;
			margin-bottom: 10px;
			
			b {
				@extend %bold;
			}
		}
	}
</style>