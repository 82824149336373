const axios = require('axios');

export default {
	state: {
		user: null,
		history: null,	
		loading: false,
	},
	getters: {
		user: state => {
			return state.user
		},		
		balance: state => {
			return state.user.saldo 
		},		
		authLoading: state => {
			return state.loading
		},		
		history: state => {
			return state.history
		},		
	},
	actions: {
		register({ state, commit }, payload) {
			state.loading = true
			return new Promise((resolve, reject) => {		
				axios.get('/sanctum/csrf-cookie').then(() => {

					axios.post('/api/register', payload)
						.then((response) => {
							console.log(response)
							commit('setUser', response.data)
							resolve()
						})
						.catch(error => {
							console.log(error.response.data.errors)
							state.loading = false
							reject(error.response)
						})

				})
			})
		},
		login({ state, commit }, payload) {
			state.loading = true
			return new Promise((resolve, reject) => {

				axios.get('/sanctum/csrf-cookie').then(() => {
					
					axios.post('/api/login', payload)
						.then((response) => {
							commit('setUser', response.data)
							resolve(response.data)
							state.loading = false
						})
						.catch(() => {
							reject('Las credenciales enviadas no concuerdan con nuestros registros')
							state.loading = false
						})
				});			
			})
		},
		logout({ state, commit }) {				
			state.loading = true
			return new Promise((resolve) => {
				axios.get('/api/logout')
					.then(() => {
						commit('clearUser')
						state.loading = false
						resolve()
					})
					.catch(error => {
						console.log(error)
					})
			})			
		},
		storeAccount({state, commit}, payload) {
			state.loading = true
			return new Promise((resolve, reject) => {
					
				axios.post('/api/account', payload)
					.then((response) => {
						commit('addUserAccount', response.data)
						resolve(response.data)
						state.loading = false
					})
					.catch(() => {
						reject('Error al Registrar')
						state.loading = false
					})		
			})			
		},
		updateAccount({state, commit}, payload) {
			state.loading = true
			return new Promise((resolve, reject) => {
					
				axios.put('/api/account/' + payload.id, payload)
					.then((response) => {
						commit('updateUserAccount', payload)
						resolve(response.data)
						state.loading = false
					})
					.catch(() => {
						reject('Error al Registrar')
						state.loading = false
					})		
			})			
		},
		removeAccount({state, commit}, payload) {
			state.loading = true
			return new Promise((resolve, reject) => {
					
				axios.delete('/api/account/' + payload)
					.then((response) => {
						commit('removeUserAccount', payload)
						resolve(response.data)
						state.loading = false
					})
					.catch(() => {
						reject('Error al Registrar')
						state.loading = false
					})		
			})			
		},
		updateUser({ state, commit }, payload) {
			state.loading = true
			return new Promise((resolve, reject) => {

				axios.put('/api/profile/' + state.user.id, payload)
					.then(response => {
						commit('updateUser', payload)
						resolve(response.data)
					})
					.catch(error => {
						state.loading = false
						reject(error)
					})
			})
		},
		getHistory({ state, commit }, payload) {
			state.loading = true
			return new Promise((resolve, reject) => {
				let url = window.location.hostname == 'localhost'
					? 'http://localhost:3000/history'
					: '/api/history?page=' + payload.page + '&start=' + payload.dates.start + '&end=' + payload.dates.end
				axios.get(url)
					.then(response => {
						console.log(response.data)
						commit('getHistory', response.data)
						resolve(response)
					})
					.catch(error => {
						console.log(error)
						reject(error)
						state.loading = false
					})
			})
		},
		getBalance({state, commit}) {
			if (! state.user.corredor) {
				state.loading = true
				
				return new Promise((resolve, reject) => {
					axios.get('/api/balance')
						.then(response => {
							commit('setBalance', response.data)
							resolve(response)
						}) 
						.catch(error => {
							state.loading = false
							console.log(error)
							reject(error)
						}) 
				})
			}
		}
	},
	mutations: {
		setUser(state, payload){
			state.user = payload
			localStorage.setItem('user', JSON.stringify(payload))
			axios.defaults.headers.common['Authorization'] = `Bearer ${payload.token}`
			state.loading = false
		},
		clearUser(state) {
			localStorage.removeItem('user')
			state.user = null
		},
		setBalance(state, payload) {
			state.user.saldo = payload.balance
			state.user.currency_symbol = payload.currency_symbol
			
			if(payload.bonus) {
				state.user.bonus = payload.bonus
			}
			
			state.loading = false
			localStorage.setItem('user', JSON.stringify(state.user))
		},
		updateBalance(state, payload) {
			state.user.saldo += payload
			localStorage.setItem('user', JSON.stringify(state.user))
		},
		updateBonus(state, payload) {
			state.user.bonus += payload
			localStorage.setItem('user', JSON.stringify(state.user))
		},
		addUserAccount(state, payload) {
			if (!state.user.accounts) {
				this.state.user.accounts = []
			}

			state.user.accounts.push(payload.account)
			localStorage.setItem('user', JSON.stringify(state.user))
			state.loading = false
		},
		updateUserAccount(state, payload) {			
			let index = state.user.accounts.findIndex(account => account.id == payload.id)
			let updated = {
				id: payload.id,
				user_id: state.user.id,
				banco_id: payload.banco,
				nrocuenta: payload.nrocuenta,
				tipo: payload.tipo,				
			}
        	state.user.accounts.splice(index, 1, updated)
			localStorage.setItem('user', JSON.stringify(state.user))
			state.loading = false
		},
		removeUserAccount(state, payload) {
			let index = state.user.accounts.findIndex(account => account.id == payload)
			state.user.accounts.splice(index, 1)
			localStorage.setItem('user', JSON.stringify(state.user))
			state.loading = false			
		},
		updateUser(state, payload) {
			state.user.name = payload.name
			state.user.email = payload.email
			state.user.phone = payload.phone
			state.user.printer = payload.printer
			state.user.logro = payload.logro
			state.user.currency_id = payload.currency_id
			localStorage.setItem('user', JSON.stringify(state.user))
			state.loading = false
		},
		getHistory(state, payload) {
			state.history = payload.bets
			state.history.profit = payload.profit
			state.history.sells = payload.sells
			state.history.comision = payload.comision
			state.loading = false
		},
	},
}