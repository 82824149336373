import Vue from 'vue'
const axios = require('axios')

export default {
	state: {
		menu: [],
		submenu: [],
		games: [],
		exotics: [],
		betType: false,
		bets: [],
		loading: false,
		onBet: 0,
		total: 0,
		amount: 1,
		noTeamBets: [4, 6, 9, 10, 13, 14, 17, 18, 19, 22, 24],
		showDetail: false,
		lastBet: null,
		forbiddenPlay: null,
		bet: null,
		useBonus: false,
	},
	getters: {
		categories: state => {
			return state.menu
		},		
		subcategories: state => {
			return state.submenu
		},		
		games: state => {
			return state.games
		},		
		exotics: (state) => (game) => {
			return state.exotics.filter(exotic => exotic.idgame === game)
		},		
		bets: state => {
			return state.bets
		},		
		betType: state => {
			return state.betType
		},		
		betsLoading: state => {
			return state.loading
		},
		noTeamBets: state => {
			return state.noTeamBets
		},
		onBet: state => {
			return state.onBet
		},
		total: state => {
			return state.total
		},
		amount: state => {
			return state.amount
		},
		showDetail: state => {
			return state.showDetail
		},
		forbiddenPlay: state => {
			return state.forbiddenPlay
		},
		lastBet: state => {
			return state.lastBet
		},
		bet: state => {
			return state.bet
		},
		useBonus: state => {
			return state.useBonus
		},
	},
	actions: {
		getCategories({ state, commit }) {

			return new Promise((resolve, reject) => {
				let url = window.location.hostname == 'localhost' ? 'http://localhost:3000/menu' : '/api/categories'
				Vue.set(state, 'loading', true)
					axios.get(url)
						.then(response => {
							commit('getCategories', response.data)
							resolve(response)
						})
						.catch(error => {
							console.log(error)
							reject(error)
							Vue.set(state, 'loading', false)
						})						
			})
		},
		getSubcategories({ state, commit }, payload) {			
			return new Promise((resolve, reject) => {
				let url = window.location.hostname == 'localhost' ? 'http://localhost:3000/submenu' : '/api/submenu?menu=' + payload
				Vue.set(state, 'loading', true)
				axios.get(url)
				.then(response => {					
						commit('getSubategories', response.data)
						resolve(response)
					})
					.catch(error => {
						console.log(error)
						reject(error)
						Vue.set(state, 'loading', false)
					})
			})
		},			
		getGames({ state, commit }, payload) {
			return new Promise((resolve, reject) => {
				let url = window.location.hostname == 'localhost' ? 'http://localhost:3000/games' : '/api/game?submenu=' + payload
				Vue.set(state, 'loading', true)
				axios.get(url)
				.then(response => {										
					commit('clearSubcategoryGames', payload)
					commit('getGames', response.data)
						resolve(response)
					})
					.catch(error => {
						console.log(error)
						reject(error)
						Vue.set(state, 'loading', false)
					})
			})
		},			
		getExotics({ state, commit }, payload) {
			return new Promise((resolve, reject) => {
				let url = window.location.hostname == 'localhost' ? 'http://localhost:3000/exotics' : '/api/games_prob?idgame=' + payload
				Vue.set(state, 'loading', true)
				axios.get(url)
				.then(response => {										
					commit('getExotics', response.data)
						resolve(response)
					})
					.catch(error => {
						console.log(error)
						reject(error)
						Vue.set(state, 'loading', false)
					})
			})
		},
		toggleBet({ state, commit }, payload) {
			Vue.set(state, 'loading', true)			
			commit('toggleBet', payload)
		},
		storeBets({state, commit}, payload) {
			console.log(payload)
			
			return new Promise((resolve, reject) => {
				state.loading = true
				if (window.location.hostname == 'localhost') {
					setTimeout(() => {
						let response = {"title":"valida","id":[427560],"message":"Apuesta  427560 registrada con exito <br> Suerte !!!","saldo":4690.949999999999,"juego":"312.00"}
						commit('storeBets', response)
						resolve(response)
					}, 1000)
				} else {
					
					axios.post('/api/bet', payload)
						.then(response => {
							if (response.data.title == 'Jugada Prohibida') {
								reject(response.data)
								commit('forbiddenBet', response)
								state.loading = false
							} else {
								commit('storeBets', response)
								resolve(response)
							}
						})
						.catch(error => {
							console.log(error)
							state.loading = false
							reject(error)
						})
				}
			})
		},
		getRules({ state, commit }, payload) {
			state.loading = true
			return new Promise((resolve, reject) => {
				axios.get('/api/rule', {
					params: payload,
				
				}).then(response => {
					if (response.data.message != "Valida") {
						commit('setForbiddenPlay', response)													
					}
					resolve(response.data.message)
					state.loading = false
					})
					.catch(error => {
						console.log(error)
						reject(error)
						state.loading = false
					})
			})
		},
		getBet({ state, commit }, payload) {
			state.loading = true
			return new Promise((resolve, reject) => {
				axios.get('/api/bet/' + payload)
					.then(response => {
						commit('getBet', response)
						resolve(response)
					})
					.catch(error => {
						reject(error)
						state.loading = false
					})
			})
		}
	},
	mutations: {
		getCategories(state, payload) {
			Vue.set(state, 'menu', payload)
			Vue.set(state, 'loading', false)
		},

		getSubategories(state, payload) {
			Vue.set(state, 'submenu', [...state.submenu, ...payload.filter(submenu => submenu.games_count > 0)])
			Vue.set(state, 'loading', false)
		},

		getGames(state, payload) {
			Vue.set(state, 'games', [...state.games, ...payload])
			Vue.set(state, 'loading', false)
		},

		clearGames(state) {
			state.submenu = []
			state.games = []
			for (let index = 0; index < state.menu.length; index++) {
				const element = state.menu[index]
				element.open = false				
			}				
		},

		getExotics(state, payload) {			
			Vue.set(state, 'exotics', [...state.exotics, ...payload])
			Vue.set(state, 'loading', false)
		},
		toggleBet(state, payload) {
			if (!state.bets.includes(payload)) {
				payload.monto = 1
				state.bets.push(payload)				
			} else {
				let index = state.bets.findIndex(bet => bet == payload)
				state.bets.splice(index, 1)
			}
			Vue.set(state, 'loading', false)
		},
		toggleBetType(state) {
			state.betType = !state.betType		
		},
		setOnBet(state, payload) {			
			state.onBet = payload
		},
		setTotal(state) {
			let total = 0
			if (! state.betType) {
				for (let i = 0; i < state.bets.length; i++) {
					total += state.bets[i].probabilidad * state.bets[i].monto;					
				}
			} else {
				for (let i = 0; i < state.bets.length; i++) {
					let bet = state.bets[i]
					if(i==0)
						total = bet.probabilidad * state.amount;
					else
						total = total * bet.probabilidad;
				}
			}
			if (state.useBonus) {
				total = total - state.onBet
			}

			state.total = total			
		},
		setAmount(state, payload) {
			state.amount = payload
		},
		storeBets(state, payload) {
			state.lastBet = payload.data;
			state.bets = []
			state.loading = false
		},
		forbiddenBet(state, payload) {
			state.lastBet = payload.data;
			state.loading = false
		},
		setShowDetail(state, payload) {
			state.showDetail = payload
		},
		setForbiddenPlay(state, payload) {
			state.forbiddenPlay = payload
		},
		getBet(state, payload) {
			state.bet = payload.data.bet
			state.loading = false
		},
		clearBet(state) {
			state.bet = null
		},
		clearBets(state) {
			state.bets = []
		},
		toggleUseBonus(state) {
			state.useBonus = !state.useBonus
		}
	}
}